import { navigate } from 'gatsby'
import React, { memo, useEffect } from 'react'
import { RouteComponentProps } from '@reach/router'
import { useApolloClient } from '@apollo/client'
import { user } from '@graphql'

interface LogoutProps extends RouteComponentProps {}

const Logout: React.FC<LogoutProps> = props => {
  const client = useApolloClient()

  useEffect(() => {
    localStorage.clear()
    client.writeQuery({ query: user.FetchCurrentUser, data: { res: null } })
    navigate('/home/')
  }, [])

  return null
}

export default memo(Logout)
